import React, { useState, useEffect } from "react"
import styled from "styled-components"

const SC = {}

const ReadingProgress = ({ target }) => {
  const [readingProgress, setReadingProgress] = useState(0)
  const scrollListener = () => {
    if (!target.current) {
      return
    }

    const element = target.current
    const totalHeight =
      element.clientHeight - element.offsetTop - window.innerHeight
    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (windowScrollTop === 0) {
      return setReadingProgress(0)
    }

    if (windowScrollTop > totalHeight) {
      return setReadingProgress(100)
    }

    setReadingProgress((windowScrollTop / totalHeight) * 100)
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListener)
    return () => window.removeEventListener("scroll", scrollListener)
  })

  return (
    <SC.Wrapper>
      <SC.Progress style={{ height: `${readingProgress}%` }} />
    </SC.Wrapper>
  )
}

SC.Wrapper = styled.section`
  position: sticky;
  top: 100px;
  width: 1px;
  height: 500px;
  background-color: ${({ theme }) => theme.colors.GREY_2};
  margin-top: 100px;
  margin-left: 70px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    display: none;
  }
`

SC.Progress = styled.div`
  background-color: #000;
`

export default ReadingProgress
