import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "../components/container"
import PostCardVertical from "../components/post-card-vertical"
import ReadingProgress from "../components/reading-progress"
import { getCategoryColor, getEbooks, replaceShortcodes } from "../utils"
import theme from "../consts/theme"
import DefaultProfileImage from "../images/favicon.png"

const SC = {}

const Post = ({ data, pageContext }) => {
  const target = React.createRef()
  const post = data.ghostPost
  const categoryColor = getCategoryColor(post.tags[0].name)
  const author = post.authors[0]
  const allEbooks = getEbooks()
  const ebookAd = allEbooks[Math.floor(Math.random() * getEbooks().length)]
  let postHtml = post.html.replace(
    /href="https:\/\/admin.apetrecho.digital/g,
    'href="'
  )
  postHtml = replaceShortcodes(postHtml)

  return (
    <Layout>
      <SEO
        title={post.meta_title}
        description={post.meta_description}
        canonical={`${post.slug}/`}
        author={author.name}
        image={post.feature_image}
        jsonld={{
          "@context": "https://schema.org",
          "@type": "Article",
          publisher: {
            "@type": "Organization",
            name: "Apetrecho Digital",
            url: data.site.siteMetadata.siteUrl,
            logo: {
              "@type": "ImageObject",
              url: `${data.site.siteMetadata.siteUrl}${DefaultProfileImage}`,
            },
          },
          author: {
            "@type": "Person",
            name: author.name,
            image: {
              "@type": "ImageObject",
              url: author.profile_image,
              width: 1204,
              height: 1204,
            },
            sameAs: [author.website, author.twitter, author.facebook],
          },
          headline: post.meta_title,
          url: `${data.site.siteMetadata.siteUrl}/${post.slug}/`,
          datePublished: post.published_at,
          dateModified: post.updated_at,
          image: {
            "@type": "ImageObject",
            url: post.feature_image,
            width: 600,
            height: 400,
          },
          keywords: post.tags[0].name,
          description: post.meta_description,
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": data.site.siteMetadata.siteUrl,
          },
        }}
      />
      <SC.Container
        isFlex
        alignItems="stretch"
        justifyContent="space-between"
        flexDirection="row"
      >
        <SC.SideWrapperCategory>
          <SC.Category
            to={`/${post.tags[0].slug}/`}
            categoryColor={categoryColor}
          >
            {post.tags[0].name}
          </SC.Category>
          <SC.PublishedAt>{post.published_at}</SC.PublishedAt>
          <SC.ReadingTime>
            {post.reading_time} minutos de leitura
          </SC.ReadingTime>
          <ReadingProgress target={target} />
        </SC.SideWrapperCategory>
        <SC.Article ref={target}>
          <SC.Title>{post.title}</SC.Title>
          <SC.MobileAuthor>
            <SC.Picture
              src={author.profile_image}
              alt={author.name}
            ></SC.Picture>
            <SC.Name>{author.name}</SC.Name>
          </SC.MobileAuthor>
          <SC.Content
            categoryColor={categoryColor}
            dangerouslySetInnerHTML={{ __html: postHtml }}
          />
          <SC.EbookContainer>
            <div>
              <SC.EbookLabel>Confira:</SC.EbookLabel>
              <SC.EbookTitle>{ebookAd.title}</SC.EbookTitle>
              <SC.EbookDescription>{ebookAd.description}</SC.EbookDescription>
              <SC.EbookCTA to={ebookAd.hotmartUrl}>
                <span>ACESSAR</span>
                <FontAwesomeIcon icon="download" color="#000" />
              </SC.EbookCTA>
            </div>
            <SC.EbookCover src={ebookAd.cover} alt="Capa eBook" />
          </SC.EbookContainer>
        </SC.Article>
        <SC.SideWrapperAuthor>
          <SC.Author>
            <SC.Picture
              src={author.profile_image}
              alt={author.name}
            ></SC.Picture>
            <SC.Name>{author.name}</SC.Name>
          </SC.Author>
        </SC.SideWrapperAuthor>
      </SC.Container>
      <SC.Container
        isFlex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <SC.KeepReadingTitle>Continue aprendendo</SC.KeepReadingTitle>
        <SC.KeepReadingContainer
          isFlex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-evenly"
          containerWidth={theme.sizes.MEDIUM_CONTAINER}
        >
          {pageContext.previousItem ? (
            <PostCardVertical post={pageContext.previousItem.node} />
          ) : null}
          {pageContext.nextItem ? (
            <PostCardVertical post={pageContext.nextItem.node} />
          ) : null}
        </SC.KeepReadingContainer>
      </SC.Container>
    </Layout>
  )
}

SC.Container = styled(Container)`
  margin-top: 85px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
    padding: 0 25px;
    margin-top: 40px;
  }
`

SC.AdContainer = styled(Container)`
  margin-top: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    padding: 0 25px;
    width: 100%;
  }
`

SC.SideWrapper = styled.section`
  width: 220px;
  margin-top: 10px;
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%:
  }
`

SC.SideWrapperCategory = styled(SC.SideWrapper)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    order: 1;
    margin-bottom: 15px;
  }
`

SC.SideWrapperAuthor = styled(SC.SideWrapper)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    display: none;
  }
`

SC.Category = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  color: ${({ categoryColor }) => categoryColor};
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`

SC.PublishedAt = styled.span`
  color: ${({ theme }) => theme.colors.GREY_4};
  font-size: 16px;
  display: block;
  margin-top: 25px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 10px;
  }
`

SC.ReadingTime = styled(SC.PublishedAt)`
  font-weight: 500;
  margin-top: 5px;
`

SC.Author = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 90px;
`

SC.MobileAuthor = styled(SC.Author)`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_2};
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
`

SC.Picture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 35px;
    height: 35px;
  }
`

SC.Name = styled.span`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  color: #000;
  font-weight: 600;
  margin-left: 15px;
`

SC.Article = styled.article`
  width: 800px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    order: 3;
  }
`

SC.Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: #000;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GREY_2};
  margin-bottom: 80px;
  padding-bottom: 40px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    border: none;
    font-size: 32px;
    line-height: 40px;
    margin-top: 20px;
    padding-bottom: 0;
    margin-bottom: 40px;
  }
`

SC.Content = styled.div`
  font-size: 18px;
  line-height: 1.78;

  p {
    min-height: 1px;
  }

  h2,
  h3 {
    font-weight: 500;
  }

  h2 {
    color: #000;
    font-size: 36px;
    margin-top: 60px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      font-size: 28px;
    }
  }

  h3 {
    color: ${({ categoryColor }) => categoryColor};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  figure {
    margin: 0 auto;
    display: block;
    text-align: center;

    img {
      margin-bottom: 5px;
      height: auto;
    }

    figcaption {
      margin-bottom: 30px;
      color: ${({ theme }) => theme.colors.GREY_3};
      font-size: 16px;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: ${({ categoryColor }) => categoryColor};
    text-decoration-thickness: 2.8px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: ${({ categoryColor }) => categoryColor};
      text-shadow: 0 0 1px ${({ categoryColor }) => categoryColor};
    }
  }
`

SC.EbookContainer = styled.section`
  width: 100%;
  height: 380px;
  border-radius: ${({ theme }) => theme.sizes.DEFAULT_BORDER_RADIUS};
  background-color: ${({ theme }) => theme.colors.GREY_5};
  border-top: 5px solid ${({ theme }) => theme.colors.PRIMARY_PURPLE};
  margin-top: 90px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #000;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    justify-content: space-between;
    height: auto;
    padding: 25px;
  }
`

SC.EbookCover = styled.img`
  height: 285px;
  margin: 0;
  box-shadow: 10px 10px 10px 5px rgb(0 0 0 / 50%);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 35%;
    margin-left: 15px;
    height: auto;
  }
`

SC.EbookLabel = styled.span`
  font-size: 36px;
  line-height: 36px;
  font-weight: 600;
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 32px;
  }

  &:after {
    content: "";
    height: 4px;
    width: 80px;
    background-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
    margin-top: 10px;
    display: block;
    opacity: 0.3;
  }
`

SC.EbookTitle = styled.span`
  font-size: 22px;
  margin-top: 25px;
  display: block;
  width: 370px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.NUNITO};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    font-size: 18px;
  }
`

SC.EbookDescription = styled(SC.EbookTitle)`
  font-size: 14px;
  text-transform: none;
  line-height: 18px;
  margin-top: 10px;
  font-weight: 400;
`

SC.EbookCTA = styled(Link)`
  width: 230px;
  height: 60px;
  color: #000;
  background-color: ${({ theme }) => theme.colors.GREEN_1};
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-weight: 600;
  border-radius: ${({ theme }) => theme.sizes.ROUNDED_BORDER_RADIUS};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  transition: all 0.25s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  span {
    margin-right: 12px;
  }
`

SC.KeepReadingTitle = styled.span`
  font-size: 32px;
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  color: #000;
  margin-bottom: 50px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 24px;
    margin-bottom: 30px;
  }
`

SC.KeepReadingContainer = styled(Container)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
  }
`

export const pageQuery = graphql`
  query($pageId: String!) {
    ghostPost(id: { eq: $pageId }) {
      comment_id
      title
      html
      url
      excerpt
      reading_time
      published_at(formatString: "DD/MM/YYYY")
      updated_at(formatString: "DD/MM/YYYY")
      feature_image
      meta_description
      meta_title
      slug
      tags {
        name
        slug
      }
      authors {
        name
        profile_image
        twitter
        facebook
        website
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default Post
